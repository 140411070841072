<template>
  <Template
    v-bind="{
      ...params,
      items: getSteps(),
      formData: getFormData(),
      isSummary: this.isSummary,
      isLoading,
    }"
    :next="onNext"
    :submit="onSubmit"
  />
</template>
<script>
import { _ } from "vue-underscore";
import Template from "../components/templates/ApplicationSAIForm/ApplicationSAIForm.vue";
import { c } from "@/components/constants.js";

export default {
  components: {
    Template,
  },
  data() {
    return {
      isLoading: false,
      params: c.application_sai,
      schoolYears: [
        { name: "Freshman (1st year)", value: "FRESHMAN" },
        { name: "Sophomore (2nd year)", value: "SOPHOMORE" },
        { name: "Junior (3rd year)", value: "JUNIOR" },
        { name: "Senior (4th year)", value: "SENIOR" },
      ],
      contributingParents: [
        { name: "1 Parent, 1 Income", value: "ONE_PARENT_ONE_INCOME" },
        { name: "2 Parents, 1 Income", value: "TWO_PARENTS_ONE_INCOME" },
        { name: "2 Parents, 2 Incomes", value: "TWO_PARENTS_TWO_INCOMES" },
      ],
      households: [
        { name: "Two", value: "TWO" },
        { name: "Three", value: "THREE" },
        { name: "Four", value: "FOUR" },
        { name: "Five", value: "FIVE" },
        { name: "Six or more", value: "SIX_PLUS" },
      ],
      numOfFamilyMembersInCollege: [
        { name: "None", value: 0 },
        { name: "One", value: 1 },
        { name: "Two", value: 2 },
        { name: "Three", value: 3 },
        { name: "Four", value: 4 },
        { name: "Five", value: 5 },
        { name: "Six or more", value: 6 },
      ],
      taxes: [
        { name: "1040", value: "IRS_1040" },
        // { name: "1040A", value: "IRS_1040A" },
        // { name: "1040EZ", value: "IRS_1040EZ" },
        { name: "Not Required To File", value: "NOT_REQUIRED_TO_FILE" },
      ],
      countryList: [{ name: "California", value: "CA" }],
      isSummary: false,
    };
  },
  methods: {
    getSteps() {
      const steps = [...c.application_sai.items];
      if (this?.state?.currentFinApp?.id) this.isSummary = true;
      else steps.splice(steps.length - 1, 1);
      return steps;
    },
    getFormData() {
      const formData = {};
      console.log(this.state.currentFinApp, "currentFinApp");
      if (this.state.currentFinApp && this.state.currentFinApp.id) {
        if (this.state.currentFinApp?.parentContribution?.id) {
          const parent = this.state.currentFinApp?.parentContribution;
          if (parent?.iraDeductiblePayments)
            formData[`Parents’ Deductible Payments to IRA/KEOGH/Other`] =
              parent.iraDeductiblePayments;
          if (parent?.taxExemptInterestIncome)
            formData[`Parents’ Tax-Exempt Interest Income`] =
              parent?.taxExemptInterestIncome;
          if (parent?.untaxedPortionsIRA)
            formData[
              `Parents’ Untaxed Portions of IRA Distributions (Subtract IRA rollover; if total is less than zero, enter zero)`
            ] = parent?.untaxedPortionsIRA;
          if (parent?.untaxedPortionsPensions)
            formData[
              `Parents’ Untaxed Portions of Pensions (Subtract Pension rollover; if total is less than zero, enter zero)`
            ] = parent?.untaxedPortionsPensions;
          if (parent?.foreignIncomeExclusion)
            formData[
              `Parents’ Foreign Income Exclusion (reported on U.S. Tax Return; if less than zero, enter the absolute value)`
            ] = parent?.foreignIncomeExclusion;
          if (parent?.taxableCollegeGrant)
            formData[
              `Parents’ Taxable College Grant and Scholarship Aid (reported as income)`
            ] = parent?.taxableCollegeGrant;
          if (parent?.educationCredits)
            formData[`Parents’ Education Credits`] = parent?.educationCredits;
          if (parent?.federalWorkStudy)
            formData[`Parents’ Federal Work-study (FWS)`] =
              parent?.federalWorkStudy;
          if (parent?.annualChildSupportReceived)
            formData[
              `Annual child support received for the last complete calendar year`
            ] = parent?.annualChildSupportReceived;
        }
        if (this.state.currentFinApp?.studentContribution?.id) {
          const student = this.state.currentFinApp?.studentContribution;
          if (student?.iraDeductiblePayments)
            formData[`Student Deductible Payments to IRA/KEOGH/Other`] =
              student.iraDeductiblePayments;
          if (student?.taxExemptInterestIncome)
            formData[`Student Tax-Exempt Interest Income`] =
              student?.taxExemptInterestIncome;
          if (student?.untaxedPortionsIRA)
            formData[
              `Student Untaxed Portions of IRA Distributions (Subtract IRA rollover; if total is less than zero, enter zero)`
            ] = student?.untaxedPortionsIRA;
          if (parent?.untaxedPortionsPensions)
            formData[
              `Student Untaxed Portions of Pensions (Subtract Pension rollover; if total is less than zero, enter zero)`
            ] = parent?.untaxedPortionsPensions;
          if (student?.foreignIncomeExclusion)
            formData[
              `Student Foreign Income Exclusion (reported on U.S. Tax Return; if less than zero, enter the absolute value)`
            ] = student?.foreignIncomeExclusion;
          if (student?.taxableCollegeGrant)
            formData[
              `Student College Grant and Scholarship Aid (reported as income)`
            ] = student?.taxableCollegeGrant;
          if (student?.educationCredits)
            formData[`Student Education Credits`] = student?.educationCredits;
          if (student?.federalWorkStudy)
            formData[`Student Federal Work-study (FWS)`] =
              student?.federalWorkStudy;
          if (student?.incomeTaxPaid)
            formData[`Student U.S. Income Tax Paid (or Foreign Equivalent)`] =
              student?.incomeTaxPaid;
          if (student?.netWorthCurrentInvestments)
            formData[`Net worth of current investments`] =
              student?.netWorthCurrentInvestments;
        }
      } 
      return formData;
    },
    async onSubmit(item) {
      this.saveAppFn(item, "submit");
    },
    async onNext({ index, ...item }) {
      this.saveAppFn(item, "next");
    },
    async saveAppFn(item, type) {
      try {
        this.isLoading = true;
        const self = this;
        const app = self.state.currentFinApp;
        const user = self.state.currentUser;
        //
        const params = {
          data: {
            isActive: true,
          },
        };
        if (app) params["where"] = { id: app.id };
        else params["data"]["user"] = { connect: { id: user.id } };
        //
        const parentContribution = {};
        const studentContribution = {};
        _.each(item, (v, i) => {
          console.log(i, v);
          if (i === "Parents’ Deductible Payments to IRA/KEOGH/Other")
            parentContribution.iraDeductiblePayments = parseFloat(v || "0");
          if (i === "Parents’ Tax-Exempt Interest Income")
            parentContribution.taxExemptInterestIncome = parseFloat(v || "0");
          if (
            i ===
            "Parents’ Untaxed Portions of IRA Distributions (Subtract IRA rollover; if total is less than zero, enter zero)"
          )
            parentContribution.untaxedPortionsIRA = parseFloat(v || "0");
          if (
            i ===
            "Parents’ Untaxed Portions of Pensions (Subtract Pension rollover; if total is less than zero, enter zero)"
          )
            parentContribution.untaxedPortionsPensions = parseFloat(v || "0");
          if (
            i ===
            "Parents’ Foreign Income Exclusion (reported on U.S. Tax Return; if less than zero, enter the absolute value)"
          )
            parentContribution.foreignIncomeExclusion = parseFloat(v || "0");
          if (
            i ===
            "Parents’ Taxable College Grant and Scholarship Aid (reported as income)"
          )
            parentContribution.taxableCollegeGrant = parseFloat(v || "0");
          if (i === "Parents’ Education Credits")
            parentContribution.educationCredits = parseFloat(v || "0");
          if (i === "Parents’ Federal Work-study (FWS)")
            parentContribution.federalWorkStudy = parseFloat(v || "0");
          if (
            i ===
            "Annual child support received for the last complete calendar year"
          )
            parentContribution.annualChildSupportReceived = parseFloat(
              v || "0"
            );

          if (i === "Student Deductible Payments to IRA/KEOGH/Other")
            studentContribution.iraDeductiblePayments = parseFloat(v || "0");
          if (i === "Student Tax-Exempt Interest Income")
            studentContribution.taxExemptInterestIncome = parseFloat(v || "0");
          if (
            i ===
            "Student Untaxed Portions of IRA Distributions (Subtract IRA rollover; if total is less than zero, enter zero)"
          )
            studentContribution.untaxedPortionsIRA = parseFloat(v || "0");
          if (
            i ===
            "Student Untaxed Portions of Pensions (Subtract Pension rollover; if total is less than zero, enter zero)"
          )
            studentContribution.untaxedPortionsPensions = parseFloat(v || "0");
          if (
            i ===
            "Student Foreign Income Exclusion (reported on U.S. Tax Return; if less than zero, enter the absolute value)"
          )
            studentContribution.foreignIncomeExclusion = parseFloat(v || "0");
          if (
            i ===
            "Student College Grant and Scholarship Aid (reported as income)"
          )
            studentContribution.taxableCollegeGrant = parseFloat(v || "0");
          if (i === "Student Education Credits")
            studentContribution.educationCredits = parseFloat(v || "0");
          if (i === "Student Federal Work-study (FWS)")
            studentContribution.federalWorkStudy = parseFloat(v || "0");
          if (i === "Student U.S. Income Tax Paid (or Foreign Equivalent)")
            studentContribution.incomeTaxPaid = parseFloat(v || "0");
          if (i === "Net worth of current investments")
            studentContribution.netWorthCurrentInvestments = parseFloat(
              v || "0"
            );
        });

        if (Object.keys(parentContribution)?.length > 0) {
          params.data.parentContribution = { create: parentContribution };
        }

        if (Object.keys(studentContribution)?.length > 0) {
          params.data.studentContribution = { create: studentContribution };
        }

        console.log(params, "params");
        const { saveFinancialApplication } =
          await self.actions.financialApplication.saveFinancialApplication(
            params
          );
        self.actions.setCurrentFinApp(saveFinancialApplication);
        //
        if (saveFinancialApplication?.id) {
          self.actions.alert.showSuccess({
            message:
              type === "submit"
                ? "Saved data successfully"
                : "Data updated successfully",
          });
          if (type === "submit") {
            self.$router.push("/dashboard");
          }
        }
      } catch (e) {
        console.log(e, type, "ERROR");
        this.actions.alert.showError({
          message: "Failed to save data. Please try again.",
        });
      } finally {
        this.isLoading = false;
      }
    },
    handleScroll(e) {
      console.log("scroll");
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
